import { reactive, ref, computed, toRaw } from "vue";
import { useStore } from "vuex";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useMock from "@/utils/payment/useMock";
import Data from "@/views/Checking/spotChecking/spotCheckingControlling/payoutPreparation/payoutDetail/mock";
import { DataProps } from "@/views/Checking/spotChecking/spotCheckingControlling/payoutPreparation/payoutDetail/type";
import {
  getPayoutDetailStat,
  getPayoutDetailPageList
} from "@/API/checking/spotChecking";
import { toThousandsAndTwoDecimal } from './../../../utils/payment/numberFormat';
const payoutDetail = () => {
  const column = ref([
    {
      title: "Dealer Code",
      dataIndex: "dealerCode",
    },
    {
      title: "Dealer Name",
      dataIndex: "dealerName",
      width:450
    },
    {
      title: "VIN",
      dataIndex: "vin",
      slots: {
        customRender: "vin",
      },
      width:250
    },
    {
      title: "Dealer Trade",
      dataIndex: "dealerTrade",
      width: 120
    },
    {
      title: "Amount w/o VAT",
      dataIndex: "amountWOVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: " Amount w VAT",
      dataIndex: "amountWVat",
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    {
      title: "Operation",
      dataIndex: "operation",
      width: 250,
      align: "center",
      slots: {
        customRender: "operation",
      },
    },
  ]);
  const query = reactive({
    payoutRoundId: "",
    payoutRound: "",
    programName: "",
    totalAmountWVat: "",
    totalAmountWOVat: "",
    payoutRoundStatus: "",
    dealerCode: "",
    dealerName: "",
    vinNumber: "",
    programIds: "",
    queryDealerTrade: ""
  });
  const pagination = reactive({
    pageSize: 20,
    current: 1,
    total: 0,
  });
  // 从store里获取数据
  const { state, commit } = useStore();
  const curRow = state.spotChecking.curPayoutRowData;
  const getQuery = () => {
    query.payoutRoundId = curRow.payoutRoundId || "";
    query.payoutRound = curRow.payoutRound || "";
    query.programName = curRow.programName || "";
    query.totalAmountWVat = curRow.amountWVat || "";
    query.totalAmountWOVat = curRow.amountWOVat || "";
    query.payoutRoundStatus = curRow.payoutRoundStatus || "";
    query.programIds = curRow.programIds || [];
  }
  getQuery()
  // 表格列表查询
  const data = ref([])
  const statInfo = async () => {
    const data = await getPayoutDetailStat({
      dealerCode: query.dealerCode,
      dealerName: query.dealerName,
      payoutRound: query.payoutRound,
      programIds: query.programIds,
      vinNumber: query.vinNumber
    });
    query.totalAmountWOVat = toThousandsAndTwoDecimal(data.totalAmountWOVat)
    query.totalAmountWVat = toThousandsAndTwoDecimal(data.totalAmountWVat)
    query.payoutRoundStatus = data.payoutRoundStatus
  }
  const getData = async () => {
    const params = {
      dealerCode: query.dealerCode,
      dealerName: query.dealerName,
      payoutRound: query.payoutRound,
      programIds: query.programIds,
      // programId: '1',
      vinNumber: query.vinNumber,
      queryDealerTrade: query.queryDealerTrade,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize
    };
    const res = await getPayoutDetailPageList(params)
    data.value = res.content
    pagination.total = parseInt(res.totalElements)
  };

  const search = () => {
    pagination.current = 1
    getData()
  };

  // reset
  const reset = () => {
    for (const key in query) {
      query[key] = "";
    }
    getQuery()
    search();
  };
  return {
    statInfo,
    getData,
    search,
    query,
    reset,
    column,
    data,
    pagination
  };
};
export default payoutDetail;
